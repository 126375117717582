import React, { useCallback, useEffect, useState } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Box from "@amzn/meridian/box";
import { TableSortDirection } from "@amzn/meridian/table/table";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import {
  setCurrentPage,
  setIsCreateModalOpen,
  setSelectedSite,
  setTotalPages,
  TableDataRow,
} from "src/store/siteConfigPageSlice";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import edit from "@amzn/meridian-tokens/base/icon/edit";
import "../../taskConfiguration/taskConfiguration.scss";
import { filterSortAndPaginateTableData } from "src/utils/tableUtils";

interface ColumnData {
  sortKey?: string;
  name: string;
  width?: string;
}

const siteTable = () => {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState("site");
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);
  const { tableData, searchField, currentPage, perPage } = useAppSelector(
    (state) => state.siteConfigPage
  );
  const [sortedTableData, setSortedTableData] = useState(tableData);

  useEffect(() => {
    // filter, sorting, and pagination
    const [paginatedData, totalPages] = filterSortAndPaginateTableData(
      tableData,
      searchField,
      sortColumn,
      sortDirection,
      currentPage,
      ["id"],
      perPage
    );
    setSortedTableData(paginatedData as TableDataRow[]);
    dispatch(setTotalPages(totalPages));
  }, [sortColumn, sortDirection, tableData, searchField, currentPage, perPage]);

  useEffect(() => {
    // go back to first page when filter/sort
    dispatch(setCurrentPage(1));
  }, [searchField, sortColumn, sortDirection]);

  const columnTitles: Array<ColumnData> = [
    { sortKey: "site", name: "Site Name", width: "8%" },
    { sortKey: "country", name: "Country" },
    { sortKey: "org", name: "Org" },
    { sortKey: "subOrg", name: "Sub-Org", width: "8%" },
    { sortKey: "buildingType", name: "Building Type", width: "10%" },
    { sortKey: "region", name: "Region" },
    { sortKey: "isActive", name: "Is Active?", width: "8%" },
    { sortKey: "description", name: "Description", width: "10%" },
    { sortKey: "actAvailable", name: "ACT Available" },
    { sortKey: "headcount", name: "Average Headcount" },
    { sortKey: "tldHeadcount", name: "Allowed TLD Jobs" },
    { sortKey: "siteCoLocations", name: "Co-located Sites" },
    { sortKey: "canRecordPlacementDecisionManually", name: "Allow manual placement decisions?" },
    { name: "Edit" },
  ];

  const handleRenderColumnTitles = () => {
    return columnTitles.map(({ sortKey, name, width = "" }, idx) => {
      return (
        <TableCell
          width={width}
          key={`${idx}-${sortKey}`}
          sortColumn={sortKey && sortKey}
        >
          {name}
        </TableCell>
      );
    });
  };

  const handleRenderSortedTableData = () => {
    return sortedTableData.map((row) => {
      const tldHeadcount: number = row.tldHeadcount
        ? row.tldHeadcount
        : Math.ceil(row.headcount * 0.05);
      return (
        <UncontrolledExpandableRow key={row.id}>
          <TableCell>{row.site}</TableCell>
          <TableCell>{row.country}</TableCell>
          <TableCell>{row.org}</TableCell>
          <TableCell>{row.subOrg}</TableCell>
          <TableCell>{row.buildingType}</TableCell>
          <TableCell>{row.region}</TableCell>
          <TableCell>{row.isActive ? "Yes" : "No"}</TableCell>
          <TableCell>{row.description}</TableCell>
          <TableCell>{row.actAvailable ? "Yes" : "No"}</TableCell>
          <TableCell>{row.headcount}</TableCell>
          <TableCell>{tldHeadcount}</TableCell>
          <TableCell>{row.siteCoLocations}</TableCell>
          <TableCell>{row.canRecordPlacementDecisionManually ? "Yes" : "No"}</TableCell>
          <TableCell>
            <Button
              type="icon"
              onClick={() => {
                dispatch(setIsCreateModalOpen(true));
                dispatch(setSelectedSite(row));
              }}
            >
              <Icon tokens={edit} />
            </Button>
          </TableCell>
        </UncontrolledExpandableRow>
      );
    });
  };

  return (
    <Box
      overflowY="scroll"
      overflowX="scroll"
      type="outline"
      id="site-config-data-table"
      className="view-and-edit-tasks__table"
    >
      <Table
        headerRows={1}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showDividers={true}
        fixHeaderRows={true}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
      >
        <TableRow>{handleRenderColumnTitles()}</TableRow>
        {handleRenderSortedTableData()}
      </Table>
    </Box>
  );
};

export default siteTable;
