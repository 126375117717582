import React, { useEffect } from "react";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import AssociateInformationAUSTIN from "src/components/pages/newJMR/backgroundPage/associateInformation/associateInformationAUSTIN";
import ProviderPlacedOffWorkModal from "src/components/pages/newJMR/backgroundPage/providerPlacedOffWorkModal";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import useForm from "src/hooks/useForm";
import {
  BackgroundFormData,
  JmrFlowTypes,
  setJmrFlowType,
  setBackgroundFormData,
  setBackgroundFormErrors,
  setIsCancelModalOpen,
  setPageProgress,
  setShowCaseIdRequiredText,
  setIsProviderPlacedOffWorkModalOpen,
  createAccommodation,
} from "src/store/newJmrPageSlice";
import Loader from "@amzn/meridian/loader";
import "src/components/pages/newJMR/backgroundPage/backgroundPage.scss";
import { AUSTIN } from "./associateInformation/caseSource/constant";
import {isWithinRange} from "src/utils/helpers";
import { ApiCallStatus } from "src/store/type";

const BackgroundPageAUSTIN = () => {
  const dispatch = useDispatch();
  const { featureFlags } = useAppSelector((state) => state.user);
  const {
    jmrFlowType,
    backgroundFormData,
    backgroundFormErrors,
    backgroundFormFieldLengths,
    searchedAlias,
    isAUSTINDown,
    accommodationSavingStatus,
  } = useAppSelector((state) => state.newJmrPage);

const { validateForm, setFormValues, errors } = useForm<BackgroundFormData>({
    validations: {
      alias: {
        required: { value: true, message: "Alias is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxAliasLength,
          message: `Alias must be less than or equal to ${backgroundFormFieldLengths.maxAliasLength} characters.`,
        },
      },
      fullName: {
        required: { value: true, message: "Full name is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxFullNameLength,
          message: `Full name must be less than or equal to ${backgroundFormFieldLengths.maxFullNameLength} characters.`,
        },
      },
      siteLocation: {
        required: { value: true, message: "Site location is required" },
      },
      job: {
        required: { value: true, message: "Job is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxJobLength,
          message: `Job must be less than or equal to ${backgroundFormFieldLengths.maxJobLength} characters.`,
        },
      },
      employeeID: {
        required: { value: true, message: "Employee ID is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxEmployeeIdLength,
          message: `EmployeeID must be less than or equal to ${backgroundFormFieldLengths.maxEmployeeIdLength} characters.`,
        },
      },
      shift: {
        required: { value: true, message: "Shift is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxShiftCodeLength,
          message: `Shift code must be less than or equal to ${backgroundFormFieldLengths.maxShiftCodeLength} characters.`,
        },
      },
      shiftLength: {
        custom: {
          isValid: () =>  +backgroundFormData.shiftLength <= 12 && +backgroundFormData.shiftLength > 0,
          message:
            +backgroundFormData.shiftLength > 12
              ? "Shift length cannot be more than 12 hrs"
              : "Shift length must be a number greater than 0",
        },
      },
      managerAlias: {
        required: { value: true, message: "Manager alias is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxManagerAliasLength,
          message: `Manager alias must be less than or equal to ${backgroundFormFieldLengths.maxManagerAliasLength} characters.`,
        },
      },
      managerName: {
        required: { value: true, message: "Manager name is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxManagerNameLength,
          message: `Manager name must be less than or equal to ${backgroundFormFieldLengths.maxManagerNameLength} characters.`,
        },
      },
      caseID: {
        required: { value: isAUSTINDown ? false : true, message: "Case ID is required" },
        custom: {
          isValid: (value) => !value || value.length <= backgroundFormFieldLengths.maxCaseIdLength,
          message: `Case ID must be less than or equal to ${backgroundFormFieldLengths.maxCaseIdLength} characters.`,
        },
      },
      caseSource: {
        required: { value: true, message: "Case Source is required" },
      },
      restrictionStartDate: {
        required: { value: true, message: "Start date is required" },
      },
      restrictionEndDate: {
        custom: {
          isValid: () => {
            return backgroundFormData.indefiniteRestrictions || new Date(backgroundFormData.restrictionStartDate) <= new Date(backgroundFormData.restrictionEndDate);
          },
          message: "End date must be later than start date",
        },
      },
      limitedHoursPerDay: {
        custom: {
          isValid:
              () => isLimitedHoursPerDayValid(backgroundFormData),
          message: featureFlags.DP_INTEGRATION_P0 ? "Must be a number between 1 and normal shift length" : "Must be a number between 0 and normal shift length"
        },
      },
      limitedHoursPerWeek: {
        custom: {
          isValid:
              () => !backgroundFormData.cannotWorkMoreThan40Hrs // cannotWorkMoreThan40Hrs box is not checked
                  || isWithinRange(backgroundFormData.limitedHoursPerWeek, 0, 40),
          message: "Must be a number between 0 and 40",
        },
      },
    },
  });

  const isLimitedHoursPerDayValid = (backgroundFormData: BackgroundFormData) => {
    // cannot work normal shift hrs box not checked
    if(!backgroundFormData.cannotWorkNormalShiftHrs) {
      return true;
    }
    // if dp feature flag is on, then 0 is not a valid value
    if(featureFlags.DP_INTEGRATION_P0) {
      return isWithinRange(backgroundFormData.limitedHoursPerDay, 1, +backgroundFormData.shiftLength);
    }

    // if dp feature flag is off, then 0 is a valid value
    return isWithinRange(backgroundFormData.limitedHoursPerDay, 0, +backgroundFormData.shiftLength);
  }

  const handleNextClick = () => {
    if (validateForm()) {
      // if providerPlacedOffWork is checked, bypass the further pages
      if (backgroundFormData.providerPlacedOffWork) {
        dispatch(setJmrFlowType(JmrFlowTypes.ProviderPlacedOffWork))
        dispatch(createAccommodation())
      } else {
        dispatch(setPageProgress(1));
      }
    } else if (backgroundFormErrors.caseID?.error && backgroundFormData.caseSource === AUSTIN) {
      dispatch(
        createToast({
          type: "error",
          message: "Case ID is required",
          timeout: TOAST_TIMEOUT,
        })
      );
      dispatch(setShowCaseIdRequiredText(true));
    }
  };

  const handleChange = (key: keyof BackgroundFormData, value: string) => {
    let modifiedFormData = { ...backgroundFormData };

    if (modifiedFormData.caseSource === AUSTIN) {
      if (key === "siteLocation") {
        modifiedFormData = {
          ...backgroundFormData,
          caseID: "",
        };
      }

      if (backgroundFormData.alias !== searchedAlias) {
        modifiedFormData = {
          ...backgroundFormData,
          caseID: "",
          siteLocation: "",
          shift: "",
          shiftLength: 0,
          restrictionEndDate: "",
          restrictionStartDate: "",
          cannotWorkMoreThan40Hrs: false,
          cannotWorkNormalShiftHrs: false,
          indefiniteRestrictions: false,
          limitedHoursPerDay: "",
          limitedHoursPerWeek: "",
        };
      }
    }

    dispatch(setBackgroundFormData({ ...modifiedFormData, [key]: value }));
    dispatch(setBackgroundFormErrors({ ...backgroundFormErrors, [key]: undefined }));
    dispatch(setShowCaseIdRequiredText(false));
  };

  useEffect(() => {
    if (backgroundFormData) {
      setFormValues(backgroundFormData);
    }
  }, [backgroundFormData]);

  useEffect(() => {
    dispatch(setBackgroundFormErrors(errors));
    const caseSourceError = errors?.caseSource;
    if (caseSourceError?.error) {
      dispatch(
        createToast({
          type: "error",
          message: caseSourceError?.errorMessage,
          timeout: TOAST_TIMEOUT,
        })
      );
    }
  }, [errors]);

  useEffect(() => {
    if (accommodationSavingStatus === ApiCallStatus.Success) {
      dispatch(setIsProviderPlacedOffWorkModalOpen(true));
    }
  }, [accommodationSavingStatus]);

  return (
    <Box maxWidth={"80%"}>
      <Text type="h500" className="new-jmr__title_header">
        {jmrFlowType !== JmrFlowTypes.CreateNewAccommodation &&
          `Update JMR ${(backgroundFormData.alias && `for ${backgroundFormData.alias}`) || ""}`}
      </Text>
      <Text data-cy="new-jmr__title" type="d100" className="new-jmr__title">
        Case Background
      </Text>
      <AssociateInformationAUSTIN handleChange={handleChange} />
      {backgroundFormData.fullName && backgroundFormData.alias === searchedAlias && (
        <Row widths={["fill", "70px", "66px"]} className="new-jmr__button-row">
          <div></div>
          <Button type="link" onClick={() => dispatch(setIsCancelModalOpen(true))}>
            Cancel
          </Button>
          <Button data-cy="new-jmr__background__next" onClick={handleNextClick}>
            {backgroundFormData.providerPlacedOffWork && 
              accommodationSavingStatus === ApiCallStatus.Pending ? <Loader size="small"/> : "Next"}
          </Button>
        </Row>
      )}
      <ProviderPlacedOffWorkModal></ProviderPlacedOffWorkModal>
    </Box>
  );
};

export default BackgroundPageAUSTIN;
